<template>
    <modal class="ticketModel" :isShow="isShow">
        <div class="header">券列表</div>
        <div class="modal-body clearfix">
            <div class="left-box">
                <div class="recharge-box" v-scroll-anime="{up:'order-up',down:'order-down',liClass:'.item-li',marginH:12,paddingH:12,isRow:false}">
                    <div class="item-li" :class="{selected:item.Coupon_AutoID==CouponsInfo.Coupon_AutoID}" v-for="item in Coupons" :key="item" @click="couponsClick(item)">
                        <div class="name nowrap">{{item.Coupon_Name}}</div>
                        <div class="describe nowrap"  v-show="item.Is_Have_Validity==true" >{{item.Begin_Date}}至{{item.End_Date}}止</div>
                        <i class="iconfont icon-gou1"></i>
                    </div> 
                </div>
                <div class="item-operation">
                    <div class="float-right">
                        <div class="bnt-fangxiang" ref="order-up"><i class="iconfont icon-fangxiang-up"></i></div>
                        <div class="bnt-fangxiang" ref="order-down"><i class="iconfont icon-fangxiang-down"></i></div>
                    </div>
                </div>
            </div>
            <div class="right-box">
                <div class="title">优惠券详情</div>
                <div class="li">
                    使用规则：仅限堂食！
                </div>
                <div class="li">
                    使用星期：周一至周日
                </div>
                <div class="li" v-show="CouponsInfo.Is_AllDoor==true">
                    适用门店：所有门店！
                </div>
                <div class="li"  v-show="CouponsInfo.Is_AllDoor==false">
                    适用门店：部分门店
                </div>
            </div>
        </div>
        <div class="footer-box">
            <button class="btn btn-cancel" @click="hide()">返回</button>
        </div>
    </modal>
</template>

<script>

/**券列表  */
export default {
    name:"ticketModel",
    emits:["closeModel"],
    props:{ 
        isShow:Boolean,
        Coupons:Object,

    },
    data(){
        return{
            /**券的信息 */
            CouponsInfo:''
        }
    },
    mounted(){
        this.$nextTick(()=>{
            this.init();
        });
    },
    watch:{
        isShow(newValue){
            if(newValue){
                this.init();
            }
        }
    },
    methods:{
        /**初始化数据 */
        init(){
        },
        hide(){
            this.$emit("closeModel");
        },
        couponsClick(item){
            this.CouponsInfo = item
        },
    }
}
</script>

<style lang="scss">
@import "./ticketModel.scss";
</style>