<template>
    <modal class="customerModel" :isShow="isShow">
        <div class="header">选择客户
            <div class="search-box">
                <div class="search"><input type="text" placeholder="请输入姓名、手机号检索" v-model="searchText" @keyup.enter="search()"></div>
                <div class="search-bnt" @click="search()">搜索</div>
            </div>
        </div>
        <div class="modal-body">
            <div class="panel panel-table" >
                <div class="panel-heading">
                    <div class="tr">
                        <div class="td selectChecked">选择</div>
                        <div class="td name">名称</div>
                        <div class="td sex">性别</div>
                        <div class="td birthday">生日</div>
                        <div class="td phoneNumber">手机号</div>
                        <div class="td address">公司</div>
                    </div>
                </div>
                <div class="panel-body" ref="box" v-scroll-anime="{up:'order-up',down:'order-down',liClass:'.tr'}">
                    <div class="tr" v-for="item in userData" :key="item" :class="{selected:item.ClientID==SelectClient.ClientID}" @click="ClientsClick(item)" @dblclick="dblCustomerClick(item)"> 
                        <div class="td selectChecked">
                            <span class="iconfont " :class="item.ClientID==SelectClient.ClientID?'icon-iconoption1':'icon-iconoption'"></span>
                        </div>
                        <div class="td name">{{item.ClientName}}</div>
                        <div class="td sex">{{item.Gender==0?'男':'女'}}</div>
                        <div class="td birthday">{{item.Birthday}}</div>
                        <div class="td phoneNumber">{{item.Mobile}}</div>
                        <div class="td address" :title="item.Address" style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;" >{{item.Address}}</div>
                    </div>
                </div>
            </div>
            <div class="item-operation">
                <div class="float-right">
                    <div class="bnt-fangxiang" ref="order-up"><i class="iconfont icon-fangxiang-up"></i></div>
                    <div class="bnt-fangxiang" ref="order-down"><i class="iconfont icon-fangxiang-down"></i></div>
                </div>
            </div>
        </div>
        <div class="footer-box">
            <button class="btn btn-cancel" @click="hide()">取消</button>
            <button class="btn btn-confirm" @click="confirm()">确认</button>
        </div>
    </modal>
</template>

<script>

import { httpAES,cacheData } from '/src/services'
import { newGuid } from '/src/common/index.js'
import { ElMessage } from "element-plus";

/**选择客户  */
export default {
    name:"customerModel",
    emits:["closeModel","merconfirm"],
    props:{ 
        isShow:Boolean,
    },
    data(){
        return{
            /**客户信息 */
            userData:Object,
            userDataCopy:Object,
            /**选择的客户ID */
            SelectClient:Object,
            /**搜索框 */
            searchText:''
        }
    },
    mounted(){
        this.$nextTick(()=>{
             const loading = this.$loading({
                text: "获取用户数据中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$httpAES.post('Bestech.CloudPos.GetCrmClients',{},'获取用户数据中...').then((data)=>{
                loading.close();
                if(data.ResponseHeader.ResultCode!=0){
                    this.$message.error(data.ResponseHeader.ResultDesc);
                }else{
                    this.userData = data.ResponseBody
                    this.userDataCopy=data.ResponseBody
                }
            }).catch(()=>{
                 loading.close();
                this.$alert('获取客户信息失败', "提示", {confirmButtonText: "确定"});
            });
            this.init();
        });
    },
    watch:{
        isShow(newValue){
            if(newValue){
                this.init();
            }
        }
    },
    methods:{
        /**初始化数据 */
        init(){
        },
        /**搜索 */
        search(){
            let MenuTxt=this.searchText.toLowerCase();
            if(MenuTxt!=''){
                this.userData=[]
                this.userDataCopy.map(item=>{
                    if((item.ClientName||"").toLowerCase().indexOf(MenuTxt)>=0 || (item.Mobile||"").toLowerCase().indexOf(MenuTxt)>=0){
                        this.userData.push(item)
                    }
                })
            }else{
                this.userData = this.userDataCopy
            }
        },
        ClientsClick(item){
            this.SelectClient = item
        },
        /**双击 */
        dblCustomerClick(item){
            this.SelectClient = item
            this.confirm()
        },
        hide(){
            this.$emit("closeModel");
        },
        confirm(){
            this.$emit("merconfirm",this.SelectClient);
        }
    }
}
</script>

<style lang="scss">
@import "./customerModel.scss";
</style>