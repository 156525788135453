<template>
    <modal class="rechargeRecordModel" :isShow="isShow">
        <div class="header">{{Title}}</div>
        <div class="modal-body">
            <div class="tr">
                <span class="lable-txt">营业日期：</span>
                <el-date-picker class="from-date"
                    v-model="businessHours"
                    type="date"
                    placeholder=""
                    :default-value="new Date()">
                </el-date-picker>
                <div class="right">
                    <div class="card-no"><input type="text" placeholder="请输入卡号或读卡" v-model="cardNo" @keyup.enter="selectReadCard()" maxlength="20"/><i class="iconfont icon-sousuo" @click="selectReadCard()"></i></div> 
                    <div class="read-card" @click="readCard()">读卡</div>    
                </div>
            </div>

            <div class="table-box grey-table" v-table-el-height="'tableEl'">
                <el-table ref="tableEl" border :data="tableData"  style="width: 100%;"  
                v-mouse-scroll="{scrollName:'.el-table__body-wrapper'}">
                    <el-table-column type="index" label="序号" width="42" ></el-table-column>
                    <el-table-column prop="Card_No" label="卡号" min-width="85"></el-table-column> 
                    <el-table-column :label='tableTitle' min-width="190" >
                        <template #default="scope">
                            <p v-show="scope.row.Recharge_Money>0">{{scope.row.Recharge_Money}}</p>
                            <p>{{scope.row.Biz_Remark}}</p>
                        </template>
                    </el-table-column>
                    <el-table-column prop="Member_Name" label="姓名" min-width="68" ></el-table-column>
                    <el-table-column prop="Mobile" label="手机号" min-width="90" ></el-table-column>
                    <el-table-column prop="Is_Invoice" label="是否开票" min-width="60" ></el-table-column>
                    <el-table-column prop="Operate_User" label="操作人" min-width="55" ></el-table-column>
                    <el-table-column prop="Operate_Time" label="操作时间" min-width="85" ></el-table-column>
                    <el-table-column prop="BizStatus" label="状态" min-width="60" >
                        <template #default="scope">
                            <el-tooltip effect="dark" :content="scope.row?.Msg" placement="top" v-if="scope.row?.Msg">
                                <div>{{ scope.row.BizStatus }}</div>
                            </el-tooltip>
                            <div v-else>{{ scope.row.BizStatus }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="Operate" label="操作" min-width="100"> 
                        <template #default="scope">
                            <el-button type="text" size="small" @click="selectResult(scope.row)" v-if="scope.row.Biz_Status==1">查询结果</el-button>
                            <el-button type="text" size="small" @click="CardCancel(scope.row)" v-else-if="isRptDate && scope.row.Biz_Status==2 && scope.row.Is_AllowedCancel && !scope.row.Is_Canceled">撤销</el-button>
                            <el-button v-if="scope.row.Biz_Status==2 && !scope.row.Is_Canceled" @click="printReceipt(scope.row)" type="text" size="small">重印小票</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="subtotal">小计：{{tableData.length}}笔，共{{ Number(countMoney).toFixed(2)}}元</div>
        </div>
        <div class="footer-box">
            <button class="btn btn-cancel" @click="hide()">返回</button>
        </div>
    </modal>
</template>

<script>

// 充值记录
export default {
    name:'rechargeRecordModel',
    emits:["closeModel"],
    props:{
        isShow:Boolean,
        crmOpenCard:Number,
    },
    data(){
        return{
            Title:'',
            tableTitle:'',
            /**数据 记载中 */
            tableLoading:true,
            /**营业日期 */
            businessHours:new Date(),
            /**充值记录数据 */
            tableData:[],
            cardNo:'',
            cardSNR:'',
            card_AutoID:'',
            /**获取用户*/
            userInfo:'',
            //是否是当前营业日期
            isRptDate:false
        }
    },
    computed:{
        //充值总价
        countMoney(){
            let money=0;
            this.tableData?.forEach(item=>{
                if(item.Biz_Status==1 || item.Biz_Status==2){//预览成功 或 支付成功 合计金额
                    if(this.crmOpenCard==5){
                        money +=item.Recharge_Money
                    }else if(this.crmOpenCard==7){
                        money +=item.Recharge_Money +item.Cost_Money
                    }else{
                        money +=item.Cost_Money
                    }
                }
            })
            return Number(money.toFixed(4));
        }
    },
    mounted(){
        this.$nextTick(()=>{
            this.init();
        });
    },
    watch:{
        isShow(newValue){
            if(newValue){
                this.init();
            }
        }
    },
    methods:{
        /**初始化数据 */
        init(){
            this.cardNo=''
            if(this.crmOpenCard==5){
                this.Title='充值记录'
                this.tableTitle='充值信息'
            }
            if(this.crmOpenCard==7){
                this.Title='办卡记录'
                this.tableTitle='办卡信息'
            }
            if(this.crmOpenCard==8){
                this.Title='补卡记录'
                this.tableTitle='补卡信息'
            }
            let userInfo= this.$auth.getUserInfo();
            if(userInfo){//营业日期
                let rptDate=new Date(userInfo.Rpt_Date);
                this.businessHours=new Date(rptDate.setDate(rptDate.getDate()));
            }
            this.selectReadCard();
        },
        /**读卡 */
        selectReadCard(){
            this.userInfo=this.$auth.getUserInfo();
            let rptDate=(new Date(this.userInfo.Rpt_Date)).Format('yyyy-MM-dd');
            
            this.tableData=[]
            let param={
                User_ID:this.userInfo?.User_ID,
                Rpt_Date:(new Date(this.businessHours)).Format('yyyy-MM-dd'),
                Biz_Type:this.crmOpenCard, //5(充值业务)  7(CRM开卡业务)  8(CRM补卡业务)
                Card_No:this.cardNo, //卡号
                Card_AutoID:this.card_AutoID,
                Card_SN:this.cardSNR,
                Operate_User:this.userInfo?.Login_Name,//操作员
                Operate_PosName:this.userInfo?.Site_Name, //操作站点
            }
            const loading = this.$loading({
                text: "查询中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$httpAES.post("Bestech.CloudPos.GetCrmBizRecords",param).then((data)=>{
                loading.close();
                console.log(data)
                if(param.Rpt_Date==rptDate){
                    this.isRptDate=true;
                }else{
                    this.isRptDate=false;
                }
                this.card_AutoID=''
                this.cardSNR=''
                if(data.ResponseHeader.ResultCode==0){
                    if(data.ResponseBody.length>0){
                        this.tableData = data.ResponseBody
                        this.tableData.forEach(table=>{
                            let BizStatus=''
                            if(table.Biz_Status==0){
                                BizStatus='未处理'
                            }else if(table.Biz_Status==1){
                                BizStatus='预览成功';
                            }else if(table.Biz_Status==2){
                                BizStatus='成功'
                                if(table.Is_Canceled){
                                    BizStatus='撤销成功';
                                }
                            }else if(table.Biz_Status==-1){
                                BizStatus='预览失败'
                            }else if(table.Biz_Status==-2){
                                BizStatus='失败'
                            }
                            Object.assign(table,{card_info:'',BizStatus:BizStatus})
                            table.Operate_Time = (new Date(table.Operate_Time)).Format('yyyy-MM-dd hh:mm:ss')
                            if(table.Is_Invoice==true){
                                table.Is_Invoice='是'
                            }else{
                                table.Is_Invoice='否'
                            }
                        })
                    }
                }else{
                    this.$message.error(data.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error('查询失败：'+e);
                console.log('查询失败：'+e);
            })
        },
        readCard(){
            if(!this.cardNo){
                this.$webBrowser.redCardM1().then((d)=>{
                    if(d){
                        if(d.state==0){
                            this.card_AutoID=d.data.cardID;
                            this.cardNo=d.data.cardNo;
                            this.cardSNR=d.data.snr
                            this.selectReadCard()
                        }else{
                           this.$message.error('卡信息读取失败：'+d.message);
                        }
                    }
                })
            }
        },
        /**重印小票 */
        printReceipt(item){
            const loading = this.$loading({
                text: "请求打印数据中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let userInfo= this.$auth.getUserInfo();
            this.$httpAES.post("Bestech.CloudPos.GetMemberBizPrintContent",{
                Operate_User:userInfo?.Login_Name,
                User_ID:userInfo?.User_ID,
                Biz_Type:1,
                Biz_ID:item.Crm_BizID
            }).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    if(d.ResponseBody){
                        this.$webBrowser.posPrint(d.ResponseBody);
                        this.$message.success("已发送打印指令");
                    }else{
                        this.$message.error('请求设备没有相关打印设置!');
                    }
                    
                }else{
                    this.$message.error("请求打印数据失败："+d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error('请求打印数据失败：'+e);
                console.log('请求打印数据失败：'+e);
            })
        },
        /**撤销 */
        CardCancel(item){
            this.userInfo=this.$auth.getUserInfo();
            let param={
                User_ID:this.userInfo?.User_ID,
                Biz_ID:item.Crm_BizID,
                Operate_User:this.userInfo?.Login_Name,//操作员
                Operate_PosName:this.userInfo?.Site_Name, //操作站点
            }
            const loading = this.$loading({
                text: "撤销中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$httpAES.post("Bestech.CloudPos.CardRechargeCancel",param).then((data)=>{
                loading.close();
                if(data.ResponseHeader.ResultCode==0){
                    item.Is_Canceled = true;
                    item.BizStatus='撤销成功'
                    this.$message.success("撤销成功");
                }else{
                    this.$message.error(data.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error('撤销失败：'+e);
                console.log('撤销失败：'+e);
            })          
        },
        /**查询支付结果中 */
        selectResult(item){
            this.userInfo=this.$auth.getUserInfo();
            let param={
                User_ID:this.userInfo?.User_ID,
                Crm_BizID:item.Crm_BizID,
                Operate_User:this.userInfo?.Login_Name,//操作员
                Operate_PosName:this.userInfo?.Site_Name, //操作站点
            }
            
            const loading = this.$loading({
                text: "查询支付结果中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$httpAES.post("Bestech.CloudPos.RequeryCrmBizResult",param).then((data)=>{
                loading.close();
                if(data.ResponseHeader.ResultCode==0){
                    item.Is_Canceled = false;
                    item.Operate=''
                    if(data.ResponseBody?.OpenCardOK || data.ResponseBody.RechargeOK || data.ResponseBody?.ReissueOK){
                        item.BizStatus='成功';
                        item.Biz_Status=2;
                    }
                    this.$message.success("查询完成");
                }else{
                    this.selectReadCard();
                    this.$message.error(data.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error('撤销失败：'+e);
                console.log('撤销失败：'+e);
            })
        },
        hide(){
            this.$emit("closeModel");
        }
    }
}
</script>

<style lang="scss">
@import './rechargeRecordModel.scss'
</style>