<template>
    <modal class="memberPreMoneyModel" :isShow="isShow" >
        <div class="header">押金支付</div>
        <div class="modal-body">
            <div class="filter-box">
                <key-board-input class="search" placeholder="押金编码、客户姓名、手机号检索"
                    v-model="searchText"
                    :isBlurClose="true" :isKeyEnterClose="true"
                    :keyOptions="{isKeyDownEnter:true}"
                /> 
                <div class="query-bnt" @click="loadData()">查询</div>
            </div>
            <div class="data-box">
                <div class="panel-heading">
                    <div class="td order">选择</div>
                    <div class="td name">客户姓名</div>
                    <div class="td phone">手机号</div>
                    <div class="td deposit">收取押金金额</div>
                    <div class="td money">余额</div>
                    <div class="td number">押金编号</div>
                    <div class="td dkMoney">扣除金额</div>
                </div>
                <div class="scroll-box">
                    <div class="item-box" :class="{selected:selectData==item}" v-for="(item,index) in pageList" :key="index" @click="currentChange(item)">
                        <div class="tr">
                            <!-- <div class="td order">{{(pageIndex-1)*pageSize+index+1}}</div> -->
                            <div class="td order"><el-checkbox v-model="item.selected" @change="currentCheck(item,index)"/></div>
                            <div class="td name">{{item.DepositName}}</div>
                            <div class="td phone">{{item.DepositPhone}}</div>
                            <div class="td deposit">¥{{item.DepositMoney}}</div>
                            <div class="td money red">¥{{Number((item.DepositMoney-item.ConsumeMoney).toFixed(4))}}</div>
                            <div class="td number">{{item.DepositCode}}</div>
                            <div class="td dkMoney">
                                <div class="cell">
                                    <input-pattern pattern="money" keyBoard v-model="item.deductionMoney" @focus="inputFocus(item,index)" :ref="(e)=>moneyInput[index]=e" />
                                </div>
                            </div>
                        </div>
                        <div class="more-box">
                            <p>交易时间：{{(new Date(item.CREAT_TIME)).Format("yyyy/MM/dd hh:mm:ss")}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="bottom-search-box">
                <div class="page-box-bottom">
                    <page-turning class="last-page" :pageCount="pageCount" v-model="pageIndex" type="up"><i class=" iconfont icon-fangxiangquan-left"></i></page-turning>
                    <span class="page-number">{{pageIndex}}/{{pageCount}}页</span>
                    <page-turning class="next-page" :pageCount="pageCount" v-model="pageIndex"><i class=" iconfont icon-fangxiangquan-right"></i></page-turning>
                </div>
            </div>
            <div class="bottom-money">
                <div class="title">会员充值金额：<span>{{Number(unPaidMoney).toFixed(2)}}</span></div>
                <div class="right-money">小计:{{total.leng}}笔 扣除金额:<span>{{total.money.toFixed(2)}}</span></div>
            </div>
        </div>
        <div class="footer-box">
            <button class="btn btn-cancel" @click="hide()">取消</button>
            <button class="btn btn-confirm" @click="confirm()">批量抵扣</button>
        </div>
    </modal>
</template>

<script>
// 押金管理
export default {
    name:'preMoneyModel',
    emits:['close','confirm'],
    props:{
        isShow:{
            type:Boolean,
            default:false
        },
        //需要支付的金额
        rechargeMoney:{
            type:[Number,String],
            default:0
        }
    },
    data(){
        return{
            //检索内容
            searchText:'',
            //检索内容 过滤数据时用
            tempSearchText:"",
            pageIndex:1,
            listData:[],
            pageSize:6,
            //选中的数据
            selectData:null,
            money:0,
            userInfo:'',
            //需要支付的金额
            unPaidMoney:0,
            moneyInput:[]
            
        }
    },
    computed:{
        /**条件筛选 */
        listDataFilter(){
            let list=this.listData||[];
            list.forEach(it=>{
                it.selected=false;
                it.deductionMoney="";
            });
            let text=(this.tempSearchText||"").trim().toUpperCase();
            if(text){
                list=list.filter(it=>(it.DepositCode||'').toUpperCase().indexOf(text)>=0 
                    || (it.DepositName||'').toUpperCase().indexOf(text)>=0 
                    ||(it.DepositPhone||'').toUpperCase().indexOf(text)>=0);
            }
            return list;
        },
        pageList(){
            return this.listDataFilter.slice((this.pageIndex-1)*this.pageSize,this.pageIndex*this.pageSize);
        },
        pageCount(){
            return Math.ceil(this.listDataFilter.length/this.pageSize)||1;
        },
        /**合计抵扣金额 */
        total(){
            let total={money:0,leng:0};
            this.listData?.forEach(it=>{
                if(it.selected && it.deductionMoney>0){
                    total.money+=Number(it.deductionMoney) ;
                    total.leng++;
                }
                
            })
            total.money=Number(total.money.toFixed(4));
            return total;
        }
    },
    mounted(){
        this.$nextTick(()=>{
            this.emptyData();
            this.loadData();
            this.unPaidMoney=Number(Number(this.rechargeMoney).toFixed(2));
        });
    },
    watch:{
        isShow(newValue){
            if(newValue){
                this.emptyData();
                this.loadData();
                this.unPaidMoney=Number(Number(this.rechargeMoney).toFixed(2));
            }
        },
        //检索数据修改
        searchText(){
            if(this.tempSearchText!=this.searchText){//检索数据与 过滤用数据不一致时
                if(this.total.leng>0){
                    this.$confirm("重新查询后，已勾选的押金不能继续参与抵扣！", "提示", {
                        confirmButtonText: "确定",
                        cancelButtonText:"取消",
                        callback: (name) => {
                            if(name=='confirm'){
                                this.tempSearchText=this.searchText; 
                            }else{
                                this.searchText=this.tempSearchText; 
                            }
                        },
                    });
                }else{
                this.tempSearchText=this.searchText; 
                }
            }
        },
        tempSearchText(){
            this.pageIndex=1
        }
    },
    methods:{
        hide(){
            this.$emit('close');
        },
        //加载数据
        loadData(){
            if(this.total.leng>0){
                this.$confirm("重新查询后，已勾选的押金不能继续参与抵扣！", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText:"取消",
                    callback: (name) => {
                        if(name=='confirm'){
                            this.GetDepositsInfo(); 
                        }
                    },
                });
            }else{
               this.GetDepositsInfo(); 
            }
        },
        //获取押金数据
        GetDepositsInfo(){
            const loading = this.$loading({
                text: "获取押金数据中",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let userInfo=this.$auth.getUserInfo(); //获取用户id
            this.$httpAES.post("Bestech.CloudPos.GetDepositsInfo", {
                User_ID:userInfo.User_ID,
            }).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode!=0){
                    this.$message.error(d.ResponseHeader.ResultDesc)
                    return;
                }else{
                    console.log(d)
                    if(d.ResponseBody){
                        //过滤余额为零的数据
                        this.listData= d.ResponseBody.filter(it=>it.OrderFlag!=1 && Number((it.DepositMoney).compute(it.ConsumeMoney,'-'))>0);
                        this.listData?.forEach(it=>{
                            it.selected=false;
                            it.deductionMoney="";
                        })
                    }
                    this.pageIndex=1;
                }
            }).catch((e)=>{
                loading.close();
                this.$alert("押金管理数据加载失败"+e.message, "提示", {confirmButtonText: "确定"});
            });
        },
        currentChange(item){
            this.selectData=item;
        },
        inputFocus(item){
            if(!item.selected){
                item.selected=true;
                if(Number(item.DepositMoney).compute(item.ConsumeMoney,'-')>Number(this.unPaidMoney).compute(this.total.money,'-')){
                    item.deductionMoney = Number(this.unPaidMoney).compute(this.total.money,'-');
                }else{
                    item.deductionMoney = Number(item.DepositMoney).compute(item.ConsumeMoney,'-');
                }
            }
        },
        /**勾选 或 点击输入框 */
        currentCheck(item,index){
            if(item.selected){
                this.moneyInput[index].focus();
                if(Number(item.DepositMoney).compute(item.ConsumeMoney,'-')>Number(this.unPaidMoney).compute(this.total.money,'-')){
                    item.deductionMoney = Number(this.unPaidMoney).compute(this.total.money,'-');
                }else{
                    item.deductionMoney = Number(item.DepositMoney).compute(item.ConsumeMoney,'-');
                }
            }else{
                item.deductionMoney="";
            }
           
        },
        /**点击确认  */
        confirm(){
            if(Number(this.total.money)<=0){
                this.$message.error('扣除押金金额必须大于0!');
                return
            }
            if(Number(this.total.money)!=this.unPaidMoney){
                this.$message.error('待支付金额与扣除押金不一致');
                return
            }
            let param=[];
            for(let i=0;i<this.listData.length;i++){
                let it=this.listData[i];
                if(it.selected && it.deductionMoney>0){
                    if(Number(it.deductionMoney)>Number(it.DepositMoney).compute(it.ConsumeMoney,'-')){
                        this.$message.error(it.DepositName+'的扣除金额超出押金最大额度');
                        this.moneyInput[i].focus();
                        return
                    }
                    param.push({
                        Deposit_AutoID:it.Autoid,//押金单ID
                        Deposit_PayMoney:it.deductionMoney,//押金支付金额
                    })
                }
            }
            this.$emit("confirm",param);
        },
        /**清空页面数据 */
        emptyData(){
            this.selectData=null;
            this.pageIndex=1;
            this.money="";
        },
    }
}
</script>

<style lang="scss">
@import './memberPreMoneyModel.scss'
</style>